
@import url('https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Rose&display=swap');

/* GLOBALS */

h1, h2, h3, h4, h5, h6, p {
  color: white;
  margin-bottom: 3;
  font-family: 'Red Rose', cursive;
}

.App {
  text-align: center;
}

p {
  line-height: .4;
}

.bodyOfGame {
  background-color: lightpink;
}


/* GAME WRAPPERS */

.gameWrapper {
  /* margin-top: 60px; */
  border: 5px double #5c5959;
  background-color: #1d211e;
  height: 100vh;
}

.carrotTopLogo {
 border: 5px double #5c5959;
  /* height: 70px; */
  background-color: #1d211e;
}

.gameTitle {
  border: 5px double #5c5959;
  background-color: #1d211e;
}

.stats {
  border: 5px double #5c5959;
  background-color: #1d211e;
  min-height: 200px;
  min-width: 200px;
}

.comedianText {
  margin-top: 10px;
  font-size: 25px;
}

/* ENEMY PICTURE DISPLAY */

.enemyDisplay {
  border: 5px double #5c5959;
  background-color: #1d211e;
  min-width: 200px;
}

.enemyPicture {
  height: 360px;
}

.enemyPictureDisplay {
  border: 5px double #5c5959;
  background-color: #1d211e;
  padding: 0;
}

.enemyPictureContainer {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}


.mainDisplayOne {
  padding-top: 20px;
  /* border: 5px double #5c5959; */
  border-top: 5px double #5c5959;;
  border-left: 5px double #5c5959;;
  border-right: 5px double #5c5959;;
  background-color: #1d211e;
  min-height: 270px;
  text-align: center;
  min-height: 57vh;
}



/* GAME WRAPPER CONTENT */
.gameTitleText {
  font-family: 'Metal Mania', cursive;
  text-align: center;
  font-size: 78px;
  color :#8a0303;
}

.carrotTopThumbNail {
  height: 100px;
}

.statText {
  line-height: .9;
  font-size: 22px;
}
.startGameText:hover {
  background-color: #8a0303;
}
.aboutTextOnLanding:hover {
  color: #8a0303;
}

/* LANDING */

.landingWrapper{
  border: 5px double #5c5959;
  background-color: #1d211e;
  padding: 10%;
  text-align: center;
  border: 5px double white;
  /* width: 1000px;
  min-height: 550px; */
  margin: 0 auto;
  /* margin-top: 60px; */
  background-image: url(./media/blood_pix.png);
  background-size: cover;
  background-position: 21vw center;
  height: 100vh;;
}

.landingHeader {
  font-family: 'Metal Mania', cursive;
  letter-spacing: 23px;
  font-size: 68px;
  /* color :#8a0303; */
  color: white;
  margin-top: 300px;
}

.startGameButtonText {
  letter-spacing: 2px;
  margin-top: 0;
  font-size: 20px;
}

/* ABOUT */

.aboutHeader {
  font-family: 'Metal Mania', cursive;
  letter-spacing: 23px;
  font-size: 38px;
  margin-bottom: 32px;
  /* color :#8a0303; */
  color: white;
  margin-top: 30px;
}

.aboutLink {
  text-decoration: "none";
  font-family: 'Red Rose';
  font-size: 50px;
}

/* INTRO */

.introWholeWrapper{
  border: 5px double #5c5959;
  background-color: #1d211e;
  padding: 20px;
  text-align: center;
  border: 5px double white;
  /* width: 1000px;
  min-height: 550px; */
  margin: 0 auto;
  /* margin-top: 60px; */
  background-image: url(./media/blood_pix.png);
  background-size: cover;
  background-position: 21vw center;
  height: 100vh;
  position: relative;
}

.introImageWrapper {
  position: absolute;
    bottom: 0;
}

.Typewriter__wrapper {
  font-size: 35px;
}


/* .introWholeWrapper {
  border: 5px double #5c5959;
  background-color: #1d211e;
  text-align: center;
  border: 5px double white;
  width: 1000px;
  min-height: 550px;
  margin: auto 0;
  margin-top: 60px;
  background-image: url(./media/blood_pix.png);
} */

.introHeader {
  font-family: 'Metal Mania', cursive;
  font-size: 78px;
  letter-spacing: 12px;
}

.introStoryText {
  font-size: 18px;
}

.Typewriter__cursor{
   color: rgba(0, 0, 0, 0.0);
}

.carrotTopIntroImage {
  height: 400px;
  position: sticky;
  bottom: 0;
  top: 200px;
   z-index: 1;
  }
  /* position: absolute; */

.landingContinueButton{
  margin-top: 100px;
  letter-spacing: 2px;
  font-size: 20px;
    position: sticky;
  bottom: 0;
    /* border: 5px double white; */
}


/* CREATE CHARACTER */
.submitNameInput {
  background-color: #1d211e;
  border-color: white;
  border: "none";
  color: white;
  outline: 0;
  border-width: 0 0 2px;
  cursor: pointer;
}
.buttonText:hover {
  background-color: #8a0303;
  cursor: pointer;
}
.setHealthButton, .setStrengthButton {
  cursor: pointer;
}
.setHealthButton:hover, .setStrengthButton:hover {
  background-color: #8a0303;
}

.beginThyAdventureButtonText {
  cursor: pointer;
  padding-top: 6px;
}
.beginThyAdventureButtonText:hover {
  background-color:#8a0303;
}

.answerGridOne h5:hover, .buttonText h5:hover, .answerGridThree h5:hover, .answerGridFour h5:hover{
  color:#8a0303;
  cursor: pointer;
}

.answerGridOne h6:hover, .answerGridTwo h6:hover, .answerGridThree h6:hover, .answerGridFour h6:hover{
  color:#8a0303;
  cursor: pointer;
}

.battleButtonText {
  cursor: pointer;
}
.battleButtonText:hover {
  background-color: #8a0303;
  color: white!important;
}

.mainDisplayOneText {
  color: white;
  margin-bottom: 3;
  font-family: 'Red Rose', cursive;
}
.mainDisplayOne h6 {
  font-size: 25px!important;
}

.mainDisplayTwoText {
  
}

.row-one {

}
.row-two {
  
}
.row-three {
  
}

/* BUTTONS */

.buttonText {
  text-align: center;
  font-size: 30px;
  border: double white;
  width: 190px;
  height: 40px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 10px;
  
}

.buttonText:hover {
  color: white!important;
}

.buttonTextIntro {
  text-align: center;
  font-size: 30px;
  border: double white;
  width: 190px;
  height: 40px;
  /* margin: 0 auto; */
  margin-top: 320px;
  cursor: pointer;
}
.buttonTextIntro:hover{
  background-color: #8a0303;
}

.beginThyAdventureButtonText {
  text-align: center;
  font-size: 30px;
  border: double white;
  width: 260px;
  height: 70px;
  margin: 0 auto;
  margin-top: 50px;
  line-height: 1;
}
.setHealthButton {
  text-align: center;
  font-size: 30px;
  border: double white;
  width: 300px;
  height: 37px;
  margin: 0 auto;
  margin-top: 50px;
  line-height: 1;
}

.setStrengthButton {
  text-align: center;
  font-size: 30px;
  border: double white;
  width: 340px;
  height: 37px;
  margin: 0 auto;
  margin-top: 50px;
  line-height: 1;
}



.battleButtonText {
  text-align: center;
  font-size: 30px;
  border: double white;
  width: 190px;
  height: 40px;
  margin: 0 auto;

}

.battleButtonsWrapper {
  margin-bottom: 20px;
}

.attackRoll {
  margin-bottom: 0;
}

.buttonText {
  text-align: center;
  font-size: 30px;
  border: double white;
  width: 190px;
  height: 40px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 10px;
}

.startGameText {
  text-align: center;
  font-size: 30px;
  border: double white;
  width: 260px;
  height: 40px;
  margin: 35px auto 10px auto;
}




/* VICTORY */

.victoryWrapper{
  padding: 20px;
  text-align: center;
  border: 5px double white;
  width: 1000px;
  min-height: 550px;
  margin: 0 auto;
  margin-top: 60px;
  background-image: url(./media/blood_pix.png);
  background-color: #1d211e;
}

/* CHAPTER  */

.chapterTwoWrapper {
  text-align: "center";
}


/* WON  */

.wonWrapper{
  border: 5px double #5c5959;
  background-color: #1d211e;
  /* padding: 20px; */
  text-align: center;
  border: 5px double white;
  /* width: 1000px;
  min-height: 550px; */
  margin: 0 auto;
  /* margin-top: 60px; */
  background-image: url(./media/won3.png);
  background-size: cover;
  background-position: 21vw center;
  height: 100vh;;
  
}

.wonHeader {
  font-family: 'Metal Mania', cursive;
  letter-spacing: 23px;
  font-size: 75px;
  /* color :#8a0303; */
  color: white;
  margin-top: 90px;
  text-shadow: 2px 2px 30px white;
}

.endTypedText {
  color: black;
    background-color: #ffffff;
  border: 1px solid black;
  opacity: 0.8;
}

.chapterTwoWrapper h5{
  font-size: 25px;
}

.chapterTwoWrapper h5:hover {
  color: #8a0303;
  cursor: pointer;
}

.row-two {
  min-height: 300px;
}

.btn {
  cursor: pointer;
  font-size: 25px;
  color: white!important;
  font-size: 25px!important;
}

.btn:Hover {
  background-color: #8a0303;
}

.choice-btn {
  cursor: pointer;
  font-size: 25px;
  color: white!important;
  font-size: 25px!important;
}
.choice-btn:hover {
  color:#8a0303!important;
}

.col-choice-btn h6, .col-choice-btn h5 {
  cursor: pointer;
}

.col-choice-btn h6:hover, .col-choice-btn h5:hover {
  background-color: #8a0303;
}